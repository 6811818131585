import { css } from '@emotion/react'
import { BadgeComponent } from 'components/Badge/Badge'
import ContentsBox from 'components/ContentsBox'
import DownloadSpreadSheet from 'components/DownloadSpreadSheet'
import { rebuildSpreadSheetDataForApi } from 'components/DownloadSpreadSheet/SelectFilterForSpreadSheet/SelectFilterForSpreadSheet'
import { disposeCursor } from 'components/ListByCursor/ListByCursor.helpers'
import RoundFillBox from 'components/RoundFillBox'
import TableComponent from 'components/TableComponent'
import FilterPart from 'components/TableComponent/FilterPart'
import PeriodDropdownFilter from 'components/TableComponent/PeriodDropdownFilter'
import {
  PERIOD_DROPDOWN_INITIAL_VALUE,
  PeriodDropdownValuesType,
  isTimeFilter,
} from 'components/TableComponent/PeriodDropdownFilter/PeriodDropdownFilter.types'
import TableCell from 'components/TableComponent/TableCell'
import { TableRow } from 'components/TableComponent/TableComponent.styles'
import { TableCellConfig } from 'components/TableComponent/TableComponent.types'
import TableTitle from 'components/TableComponent/TableTitle'
import TopInfo from 'components/TopInfo'
import TopInfoRow from 'components/TopInfo/TopInfoRow'
import dayjs from 'dayjs'
import { workStatusToKorean } from 'hooks/useAllMatch'
import { useGetFunnels } from 'hooks/useGetFunnels'
import useSearchFilter from 'hooks/useSearchFilter'
import { useWorkStats, workStatsName } from 'hooks/useWorkStats'
import { MutableRefObject, useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { FlexRow, TXT } from 'themes/Shared.styles'
import {
  blobGetXlsx,
  cn,
  getKeyNameByValue,
  objectKeys,
  removeUndefined,
  thousand,
} from 'utils/helpers'
import { parseToFormattedStr } from 'utils/parsers'
import { ApiServiceListItem } from '../Work.types'
import S from './WorkListCommon.styles'
import { WorkListCommonProps } from './WorkListCommon.types'

type ResponseKeyName = {
  id: string
  serviceState: string
  customerType: string
  funnel: string

  orderDate: string
  managerName: string
  buyerName: string
  phone: string
  serviceName: string

  payState: string
}

const PAY_STATE_DROPDOWN_LIST = {
  전체: '',
  결제대기: '결제 대기',
  결제완료: '결제 완료',
  취소요청: '결제 취소 요청',
  결제취소: '결제 취소',
  취소불가: '취소 불가',
} as const
const CUSTOMER_TYPE_DROPDOWN_LIST = {
  전체: '',
  '일반 회원': '일반 회원',
  '정기 회원': '정기 회원',
} as const

const defaultListTableConfigs: TableCellConfig<ResponseKeyName>[] = [
  {
    _type: 'dropdown',
    _id: 'payState',
    _label: '결제 상태',
    _size: 106,
    _position: 'left',
    _data: PAY_STATE_DROPDOWN_LIST,
    _value: '',
  },
  {
    _type: 'dropdown',
    _id: 'customerType',
    _label: '회원 분류',
    _size: 106,
    _position: 'left',
    _data: CUSTOMER_TYPE_DROPDOWN_LIST,
    _value: '',
  },
  {
    _type: 'dropdown',
    _id: 'funnel',
    _label: '주문 경로',
    _size: 106,
    _position: 'left',
    _data: {},
    _value: '',
  },
  {
    _type: 'order',
    _id: 'orderDate',
    _label: '주문 일시',
    _size: 160,
    _order: 'INITIAL',
  },
  {
    _type: 'default',
    _id: 'managerName',
    _label: '담당자명',
    _size: 84,
  },
  {
    _type: 'default',
    _id: 'buyerName',
    _label: '주문자명',
    _size: 84,
  },
  {
    _type: 'default',
    _id: 'phone',
    _label: '전화번호',
    _size: 120,
  },
  {
    _type: 'default',
    _id: 'serviceName',
    _label: '서비스명',
    _position: 'left',
    _size: 'auto',
  },
]
export const USER_QUERY_DROPDOWN_LIST = {
  전화번호: '전화번호',
  주문자명: '주문자명',
  담당자명: '담당자명',
  서비스명: '서비스명',
} as const

// EDIT:THIS
const responseParser = (response: ApiServiceListItem[]): ResponseKeyName[] => {
  const rebuild = response.map((item: ApiServiceListItem, index: number) => {
    return {
      id: item.id,
      payState: item.paymentStatus,
      customerType: item.customerType === 'REGULAR' ? '정기 회원' : '일반 회원',
      funnel: item.orderFunnel,
      orderDate: item.createAt
        ? dayjs(item.createAt).format('YYYY-MM-DD(dd) HH:mm')
        : '-',
      managerName: item.issuer,
      buyerName: item.customerName,
      serviceName: item.serviceName,
      phone: parseToFormattedStr(item.customerPhone, 'phone'),
    } as ResponseKeyName
  })
  return rebuild
}

const WorkListCommon = ({ _css, _type, ...props }: WorkListCommonProps) => {
  const navigate = useNavigate()
  const topInfoRef = useRef() as MutableRefObject<HTMLDivElement>
  const headerRef = useRef() as MutableRefObject<HTMLDivElement>
  const [tableHeaderOffset, set_tableHeaderOffset] = useState<number>(0)
  const [headerOffset, set_headerOffset] = useState<number>(0)
  const workStats = useWorkStats()
  const { currentConfigs: serviceTableConfigs } = useGetFunnels(
    defaultListTableConfigs
  )

  useEffect(() => {
    if (topInfoRef.current && headerRef.current) {
      const headerSize =
        topInfoRef.current.getBoundingClientRect().height +
        headerRef.current.getBoundingClientRect().height
      set_headerOffset(topInfoRef.current.getBoundingClientRect().height)
      set_tableHeaderOffset(headerSize)
    }
  }, [])

  const [selectedId, set_selectedId] = useState<string>('')

  const { searchParams, initialValues, handleSetLocation } = useSearchFilter(
    {
      filterList: PERIOD_DROPDOWN_INITIAL_VALUE,
      queryList: USER_QUERY_DROPDOWN_LIST,
    },
    // query object -> filterList
    ({ start, end, month, year, periodType, ...rest }, queryName) => {
      return {
        period: {
          start: start ? dayjs(start, 'YYYY-MM-DD') : null,
          end: end ? dayjs(end, 'YYYY-MM-DD') : null,
        },
        monthValue: month ?? '',
        yearValue: year ?? '',
        periodType: isTimeFilter(periodType) ? periodType : 'all',
        terms: queryName ? String(rest[queryName]) : '',
        queryName: queryName ? String(queryName) : '',
      }
    },
    // filterList => query object
    ({ queryName = 'search', terms = '', periodType, ...rest }) => {
      return removeUndefined({
        periodType: periodType === 'all' ? '' : periodType,
        start: rest.period.start?.format('YYYY-MM-DD'),
        end: rest.period.end?.format('YYYY-MM-DD'),
        month: rest.monthValue ?? 0,
        year: rest.yearValue ?? 0,
        searchType: queryName,
        searchValue: terms,
      })
    }
  )

  const apiURI = useMemo(() => {
    // EDIT:THIS
    return `/order/list?take=10&status=${workStatusToKorean[_type]}${
      window.location.search ? '&' + searchParams.toString() : ''
    }`
  }, [searchParams, _type])
  return (
    <ContentsBox _type="relative" _css={css``}>
      {/* ContentsBox에서 스타일 상속 받음 */}
      <S.Wrap _css={_css} {...props}>
        {/* EDIT:THIS */}
        <TopInfo ref={topInfoRef}>
          {objectKeys(workStatsName).map((stat) => {
            return (
              <TopInfoRow
                key={'top-indo-' + stat}
                _label={workStatsName[stat]}
                _contents={`${workStats ? thousand(workStats[stat]) : 0}건`}
              />
            )
          })}
        </TopInfo>
        {/* EDIT:THIS */}
        <TableTitle
          ref={headerRef}
          _css={css`
            top: ${headerOffset}px;
          `}
          _left={
            <FlexRow
              gap={20}
              _css={css`
                & > div {
                  white-space: nowrap;
                }
              `}
            >
              <TXT _textStyle="Display/3 Bold" _color="Grayscale/Gray Dark">
                {workStatusToKorean[_type]}
              </TXT>
            </FlexRow>
          }
          _right={
            <FlexRow gap={16}>
              <DownloadSpreadSheet
                _tableConfig={
                  _type === 'completed'
                    ? [
                        '주문상태',
                        '회원분류',
                        '주문경로',
                        '주문일시',
                        '담당자명',
                        '주문자명',
                        '전화번호',
                        '서비스명',
                        '결제상태',
                        '시공 완료일',
                      ]
                    : [
                        '주문상태',
                        '회원분류',
                        '주문경로',
                        '주문일시',
                        '담당자명',
                        '주문자명',
                        '전화번호',
                        '서비스명',
                        '결제상태',
                      ]
                }
                _emitValue={async (value) => {
                  const rebuild = rebuildSpreadSheetDataForApi(value)
                  let { queryString } = rebuild
                  queryString =
                    queryString +
                    (queryString.includes('?') ? '&' : '?') +
                    `status=${workStatusToKorean[_type]}`
                  blobGetXlsx(
                    'task',
                    `/order/list/excel${queryString}`,
                    `${workStatusToKorean[_type].replace(/\s/, '_')}_목록`
                  )
                }}
              />
            </FlexRow>
          }
        />
        {/* EDIT:THIS */}
        <FilterPart<PeriodDropdownValuesType>
          _value={initialValues}
          _placeholder="주문번호 또는 주문자명, 상품명 검색"
          _emitValue={(value) => {
            handleSetLocation(value)
          }}
          _queryNameList={USER_QUERY_DROPDOWN_LIST}
          _queryNameFormat={{ phone: 'phone' }}
          _FilterContents={({ _value, ...props }) => {
            return <PeriodDropdownFilter _value={_value} {...props} />
          }}
        />
        {/* EDIT:THIS */}
        <RoundFillBox>
          <TableComponent<ResponseKeyName>
            _type="task"
            _url={apiURI}
            _topOffset={tableHeaderOffset}
            _config={serviceTableConfigs}
            _parser={(response: DoubleDListWithPaging<ApiServiceListItem>) => {
              const rebuild = responseParser(response.list)
              return {
                _list: rebuild,
                _cursor: disposeCursor(response.paging.cursor, 'cursor'),
              }
            }}
            _ListContent={({ _row, _index }) => {
              const rowId = _row.id ? String(_row.id) : String(_index)
              return (
                <TableRow
                  className={cn({
                    active: selectedId === rowId,
                  })}
                  onClick={(e) => {
                    e.stopPropagation()
                    set_selectedId(rowId)
                  }}
                  onDoubleClick={(e) => {
                    e.stopPropagation()
                    // EDIT:THIS !
                    navigate(`/work/${_type}/${rowId}`)
                  }}
                >
                  {serviceTableConfigs.map((item, columnIndex) => {
                    // EDIT:THIS
                    const _id = item._id as keyof ResponseKeyName
                    const _value = _row[_id]
                    return (
                      <TableCell
                        key={'body' + _index + columnIndex}
                        _position={item._position}
                        _size={item._size}
                      >
                        {_id === 'payState' ? (
                          <BadgeComponent
                            _value={
                              getKeyNameByValue(
                                PAY_STATE_DROPDOWN_LIST,
                                _value
                              ) ?? ''
                            }
                          />
                        ) : (
                          <div>{_value}</div>
                        )}
                      </TableCell>
                    )
                  })}
                </TableRow>
              )
            }}
          />
        </RoundFillBox>
      </S.Wrap>
    </ContentsBox>
  )
}
export default WorkListCommon
