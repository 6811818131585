import { css } from '@emotion/react'
import { GrayScaleFill, PrimaryButton } from 'components/Buttons'
import CheckBoxRound from 'components/CheckBoxRound'
import Modal from 'components/Modal'
import Popup from 'components/Popup'
import RadioButton from 'components/RadioButton'
import { PeriodDropdownValuesType } from 'components/TableComponent/PeriodDropdownFilter/PeriodDropdownFilter.types'
import { TableCellConfig } from 'components/TableComponent/TableComponent.types'
import { isArray } from 'mathjs'
import { useEffect, useState } from 'react'
import { FlexColumn, FlexRow } from 'themes/Shared.styles'
import { parserForPeriodQuery } from 'utils/helpers'
import { parseObjectToQueryString } from 'utils/parsers'
import ByDates from '../ByDates'
import { DropdownFiltersType } from '../DownloadSpreadSheet.types'
import S from './SelectFilterForSpreadSheet.styles'
import { SelectFilterForSpreadSheetProps } from './SelectFilterForSpreadSheet.types'
const rebuildDropdownValuesToObject = (value: DropdownFiltersType[]) => {
  return value.reduce((prev, curr) => {
    prev[curr.key] = curr.value
    return prev
  }, {} as QueryObject)
}
export const rebuildSpreadSheetDataForApi = (
  value: {
    fields: string[]
    periodFilterValues?: PeriodDropdownValuesType | null | undefined
    dropdownValues?: DropdownFiltersType[] | null | undefined
  },
  cb?: (selector: DropdownFiltersType[]) => {
    [key in string | number]: string | number
  }
) => {
  const rebuild = {
    excelHeader: value.fields.map((item) => {
      const itemWithoutWhiteSpace = item.replace(/\s/g, '')
      return itemWithoutWhiteSpace
    }),
    ...(value.periodFilterValues && {
      ...{
        startAt: parserForPeriodQuery(value.periodFilterValues).startAt,
        endAt: parserForPeriodQuery(value.periodFilterValues).endAt,
      },
    }),
    ...(value.dropdownValues &&
      (cb
        ? {
            ...cb(value.dropdownValues),
          }
        : { ...rebuildDropdownValuesToObject(value.dropdownValues) })),
  }
  const { excelHeader, ...rest } = rebuild
  const arrayString = rebuild.excelHeader.join(`&excelHeader[]=`)
  const otherString = parseObjectToQueryString(rest)
  const queryString =
    (otherString ? otherString : '?') +
    (arrayString ? `${otherString ? '&' : ''}excelHeader[]=` + arrayString : '')
  return {
    rebuild,
    queryString,
  }
}
const isStringArray = <T extends object>(
  value: TableCellConfig<T>[] | string[]
): value is string[] => {
  return (
    value !== undefined &&
    isArray(value) &&
    !!value[0] &&
    typeof value[0] === 'string'
  )
}
const SelectFilterForSpreadSheet = <T extends object>({
  _css,
  _tableConfig,
  _noPeriod,
  _dropdownFilters,
  _emitValue,
  _open: open,
  _closeModal: set_open,
  ...props
}: SelectFilterForSpreadSheetProps<T>) => {
  const [fields, set_fields] = useState<string[]>([])
  const [allFields, set_allFields] = useState<string[]>([])
  useEffect(() => {
    if (open) {
      if (isStringArray(_tableConfig)) {
        set_fields(_tableConfig)
        set_allFields(_tableConfig)
      } else {
        set_fields(
          _tableConfig
            .filter((item) => item._id !== 'empty')
            .map((item) => String(item._label))
        )
        set_allFields(
          _tableConfig
            .filter((item) => item._id !== 'empty')
            .map((item) => String(item._label))
        )
      }
    } else {
      set_fields([])
    }
    return () => {
      set_fields([])
    }
  }, [open, _tableConfig])
  const [periodFilterValues, set_periodFilterValues] =
    useState<PeriodDropdownValuesType | null>()

  const [dropdownValues, set_dropdownValues] = useState<DropdownFiltersType[]>(
    []
  )

  useEffect(() => {
    set_dropdownValues(_dropdownFilters ? _dropdownFilters : [])
  }, [_dropdownFilters])

  const handleSubmit = () => {
    if (_emitValue) {
      let data: {
        fields: string[]
        periodFilterValues?: PeriodDropdownValuesType | null
        dropdownValues?: DropdownFiltersType[] | null
      } = {
        fields,
      }
      if (!_noPeriod && periodFilterValues)
        data.periodFilterValues = periodFilterValues
      if (dropdownValues.length > 0) data.dropdownValues = dropdownValues
      _emitValue(data)
    }
  }
  return (
    <>
      <Modal
        open={open}
        onClose={() => {
          set_open(false)
        }}
      >
        <Popup
          _title="엑셀 다운로드"
          _buttonHandler={
            <FlexRow gap={0}>
              <FlexRow gap={16} justifyContent="flex-end">
                <GrayScaleFill
                  _css={css`
                    box-shadow: none;
                    border-radius: 50px;
                    width: 148px;
                    height: 44px;
                  `}
                  onClick={() => {
                    set_open(false)
                  }}
                >
                  취소
                </GrayScaleFill>
                <PrimaryButton
                  _css={css`
                    box-shadow: none;
                    border-radius: 50px;
                    width: 200px;
                    height: 44px;
                  `}
                  onClick={() => {
                    handleSubmit()
                  }}
                >
                  적용
                </PrimaryButton>
              </FlexRow>
            </FlexRow>
          }
        >
          <S.Contents>
            <FlexColumn gap={32}>
              {!_noPeriod && (
                <ByDates
                  _emitValue={(value) => {
                    set_periodFilterValues(value)
                  }}
                />
              )}
              {dropdownValues.length > 0 ? (
                <>
                  {dropdownValues.map((dropdown) => {
                    return (
                      <FlexColumn gap={12} key={dropdown.key}>
                        <h4>{dropdown.label}</h4>
                        <FlexRow
                          gap={16}
                          _css={css`
                            justify-content: space-between;
                            & > div {
                              flex: 1;
                            }
                          `}
                        >
                          {(
                            Object.keys(dropdown.list) as KeyOf<
                              typeof dropdown.list
                            >[]
                          ).map((item) => {
                            return (
                              <RadioButton
                                key={item}
                                _mini
                                _active={dropdown.value === dropdown.list[item]}
                                onClick={() => {
                                  set_dropdownValues((prev) =>
                                    prev.map((curr) =>
                                      curr.key === dropdown.key
                                        ? {
                                            ...curr,
                                            value: dropdown.list[item],
                                          }
                                        : curr
                                    )
                                  )
                                }}
                              >
                                {item}
                              </RadioButton>
                            )
                          })}
                        </FlexRow>
                      </FlexColumn>
                    )
                  })}
                </>
              ) : (
                <></>
              )}
              <FlexColumn gap={12}>
                <h4>엑셀 포함 항목</h4>
                <S.FieldsColumn>
                  {allFields.map((item) => {
                    return (
                      <CheckBoxRound
                        _active={fields.includes(String(item))}
                        onClick={() => {
                          set_fields((prev) =>
                            fields.includes(String(item))
                              ? prev.filter((next) => next !== String(item))
                              : [...prev, String(item)]
                          )
                        }}
                        _mini
                        key={String(item)}
                      >
                        {item}
                      </CheckBoxRound>
                    )
                  })}
                </S.FieldsColumn>
              </FlexColumn>
            </FlexColumn>
          </S.Contents>
        </Popup>
      </Modal>
    </>
  )
}
export default SelectFilterForSpreadSheet
